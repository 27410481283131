<template>
	<v-main class="pa-0" :key="componentKey">
		<v-card elevation="1">
			<v-card-title
				class="text-subtitle-1 font-weight-bold"
				:class="$vuetify.theme.dark ? 'secondary darken-1' : 'grey lighten-3 grey--text text--darken-1'"
			>
				<v-icon
					small
					left
					:class="$vuetify.theme.dark ? 'secondary darken-1' : ' grey--text text--darken-1'"
					v-text="'fas fa-tasks'"
				></v-icon>
				Araç Listesi
				<v-spacer></v-spacer>
			</v-card-title>

			<v-card-text class="pa-0">
				<v-data-table
					:headers="headers"
					:options.sync="options"
					:items="bo_carListData"
					:server-items-length="bo_carListTotalCount"
					class="elevation-0"
				>
					<template v-slot:[`item.publishedDate`]="{ item }">
						{{ $moment.utc(item.publishedDate).local().format('DD-MM-YYYY HH:mm') }}
					</template>

					<template v-slot:[`item.brandName`]="{ item }">
						<img
							:src="getCarImage(item.brandName)"
							:alt="item.brandName"
							@error="imgErrorEvent"
							contain
							width="40px"
							height="40px"
						/>
					</template>

					<template v-slot:[`item.listingPrice`]="{ item }">
						{{ item.listingPrice | tl({ avoidEmptyDecimals: '' }) }}
					</template>
					<template v-slot:[`item.salesPrice`]="{ item }">
						{{ item.salesPrice | tl({ avoidEmptyDecimals: '' }) }}
					</template>

					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon @click="deleteItem(item)" v-bind="attrs" v-on="on">
									<v-icon x-small> fa-trash </v-icon>
								</v-btn>
							</template>
							<span>Listeden Kaldır</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-main>
</template>

<script>
import EventBus from '@/event-bus';
import { mapActions, mapGetters } from 'vuex';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import { BO_FETCH_GRID_DATA, BO_REMOVE_LISTED } from '@/store/modules/listing.module';

export default {
	name: 'CarList',
	components: {},
	data() {
		return {
			componentKey: 0,
			exporting: false,
			supervisorDialog: false,
			selectedItem: null,
			options: {
				itemsPerPage: 10,
				page: 1,
				sortBy: [],
				sortDesc: [true],
				mustSort: true,
			},
			headers: [
				{ text: 'Id', value: 'id', align: 'center' },
				{ text: 'Tarih', value: 'publishedDate', align: 'center' },
				{ text: 'Yıl', value: 'year', align: 'center' },
				{ text: 'Marka', value: 'brandName' },
				{ text: 'Model', value: 'modelName' },
				{ text: 'Kasa', value: 'bodyTypeName' },
				{ text: 'Yakıt', value: 'fuelTypeName' },
				{ text: 'Vites', value: 'transmissionTypeName' },
				{ text: 'Versiyon', value: 'version' },
				{ text: 'Kilometre', value: 'kilometer', align: 'right' },
				{ text: 'Liste Fiyatı', value: 'listingPrice', align: 'right' },
				{ text: 'Satış Fiyatı', value: 'salesPrice', align: 'right' },
				{ text: 'Görüntüleme', value: 'viewCount', align: 'right', width: '100px' },
				{ text: 'Favorilere Ekleme', value: 'favoriteCount', align: 'right', width: '130px' },
				{ text: 'İşlemler', value: 'actions', sortable: false, align: 'center' },
			],
		};
	},
	computed: {
		...mapGetters(['bo_carListData', 'bo_carListTotalCount']),
	},
	created() {
		this.$nextTick(() => {
			EventBus.$on('refreshRequestGrid', () => {
				this.componentKey += 1;
			});
		});
	},
	beforeDestroy() {
		EventBus.$off('refreshRequestGrid');
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchGridData: BO_FETCH_GRID_DATA,
			removeList: BO_REMOVE_LISTED,
		}),
		imgErrorEvent(e) {
			e.target.src = 'https://via.placeholder.com/40';
		},
		getCarImage(brandName) {
			if (brandName === null) return '';
			return `https://mars-contents.fra1.digitaloceanspaces.com/qa/brand_logo/${brandName}.png`;
		},
		filterBind(payload) {
			const newPayload = { ...payload, pageSize: this.options.itemsPerPage, pageNumber: 1 };
			this.fetchGridData(newPayload);
		},
		filterStatus(status, onlyThisMonth) {
			if (!this.$refs.filterRef || !status) return;

			this.options = {
				itemsPerPage: 10,
				page: 1,
				sortBy: [],
				sortDesc: [true],
				mustSort: true,
			};

			let startDate = null;
			let endDate = null;

			if (onlyThisMonth) {
				const days = parseInt(this.$moment().format('DD'), 10);

				startDate = this.$moment()
					.subtract(days - 1, 'days')
					.format('YYYY-MM-DD');
				endDate = this.$moment().add(1, 'months').subtract(days, 'days').format('YYYY-MM-DD');
			}

			const payload = { ...this.$refs.filterRef?.filter, statusId: status.id, startDate, endDate };
			const newPayload = { ...payload, pageSize: this.options.itemsPerPage, pageNumber: 1 };
			this.$refs.filterRef.filter.statusId = status.id;
			this.$refs.filterRef.filter.startDate = startDate;
			this.$refs.filterRef.filter.endDate = endDate;
			this.fetchGridData(newPayload);
		},
		deleteItem(item) {
			this.setMessageDialog({
				messageType: 'warning',
				subTitle: 'Emin misiniz?',
				text: 'Listeden kaldırmak istediğinize emin misiniz?',
				closeButtonText: 'Vazgeç',
				buttons: [
					{
						text: 'Evet',
						color: 'teal',
						action: () =>
							this.removeList(item).then(() => {
								this.disposeMessageDialog();
							}),
					},
				],
			});
		},
	},
	watch: {
		options: {
			handler(value) {
				const { sortBy, sortDesc } = value;
				const sortField = sortBy[0] ?? 0;
				const fieldIndex = this.headers.findIndex((x) => x.value === sortField);
				const orderByFieldId = fieldIndex < 0 ? 0 : fieldIndex + 1;
				const orderByDesc = sortDesc[0] ?? true;

				const payload = { ...this.$refs.filterRef?.filter };

				const newPayload = {
					...payload,
					pageSize: value.itemsPerPage,
					pageNumber: value.page,
					orderByFieldId,
					orderByDesc,
				};
				this.fetchGridData(newPayload);
			},
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.theme--light .v-data-table {
		tbody {
			tr {
				&:nth-of-type(even) {
					background-color: rgba(0, 0, 0, 0.03);

					td {
						&.v-data-table__mobile-row {
							background-color: rgba(0, 0, 0, 0.03);
						}
					}
				}
			}
		}
	}

	.theme--dark .v-data-table {
		tbody {
			tr {
				&:nth-of-type(even) {
					background-color: rgba(0, 0, 0, 0.3);

					td {
						&.v-data-table__mobile-row {
							background-color: rgba(0, 0, 0, 0.3);
						}
					}
				}
			}
		}
	}
}
.hide-chip {
	visibility: hidden;
}
</style>
